<template>
  <div id="earn" class="token-sale p-tb c-l black-bg white-sec">
    <div class="container mt-6">
      <div id="about" class="about-section style-2 p-tb">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="sec-title">
              <h1>Poker Battle <span class="date">06.10.2023</span></h1>
              <h5 class="lead">Prize pool 800$</h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 d-flex">
            <div class="leaderboard-container">
              <div class="leaderboard">
                <h2>Leaderboard</h2>
                <ol>
                  <li v-for="team in teams" :key="team.id">
                    <span class="name">{{ team.name }}</span>
                    <span class="score">Score: {{ team.totalScore }}</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center">
            <img :src="pokerBattle" alt="team">
          </div>
        </div>
        <div class="teams">
          <h1 class="lead">Teams</h1>
        </div>
        <div class="row">
          <div v-for="team in teams" :key="team.id" class="col-md-4 d-flex">
            <div class="leaderboard-container">
              <div class="leaderboard">
                <div class="card">
                  <div :id="team.id" class="card-header" role="tab">
                    <img class="team-logo mx-auto d-block" :src="team.img">
                    <h5 class="mb-o">
                      {{ team.name }}
                    </h5>
                  </div>
                  <div class="card-body">
                    <ul class="players-container">
                      <li v-for="player in team.players" :key="player.id">
                        {{ player.name }}
                        <span v-if="player.inGame" @click="removePlayer(team, player)">X</span>
                        <span v-if="player.position">{{ player.position }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import pokerBattle from '@/assets/png/5th-battle.png';
  import logo from '@/assets/png/black-gold.png';
  import gemsLogo from '@/assets/png/cg2.0.png';
  import dogLogo from '@/assets/jpg/bitDog.jpg';
  import nnnLogo from '@/assets/png/nnn.png';
  // import jrnyLogo from '@/assets/jpg/journey.jpg';
  // import carmelLogo from '@/assets/png/carmel.png';
  import ampLogo from '@/assets/png/AMP-logo.png';
  import dobbyLogo from '@/assets/png/Dobby-logo.png';
  import safemoonLogo from '@/assets/png/SafeMoon2-logo.png';
  import sharksLogo from '@/assets/png/Sharks-logo.png';
  import dateStyle from '@/styles/components/_date.scss';

  export default Vue.extend({
    name: 'elros-poker-leaderboard',

    data: () => ({
      logo,
      dateStyle,
      pokerBattle,
      totalPlayers: 40,
      currentScore: 1,
      teams: [
        {
          id: 'elros',
          name: 'Elros',
          totalScore: 0,
          img: logo,
          players: [
            {
              name: 'TaBy93',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'DollarQ',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'seregarzaev',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Drac',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'StepanZe',
              currentScore: 0,
              inGame: true,
              position: null,
            },
          ],
        },
        {
          id: 'gems',
          name: 'Crypto Gems',
          totalScore: 0,
          img: gemsLogo,
          players: [
            {
              name: 'Mr.Mouzy',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'CryptoGemsCeo',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Spieler',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Moonwalk95',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Levice',
              currentScore: 0,
              inGame: true,
              position: null,
            },
          ],
        },
        {
          id: 'dby',
          name: 'DOBBY',
          totalScore: 0,
          img: dobbyLogo,
          players: [
            {
              name: 'DADDYPEPE',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Jonnykong',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Kryptic_Pirate',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Cryptolion22',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Thecryptokrill',
              currentScore: 0,
              inGame: true,
              position: null,
            },
          ],
        },
        {
          id: 'nnn',
          name: 'NNN',
          totalScore: 0,
          img: nnnLogo,
          players: [
            {
              name: 'okal99',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'mXc',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Sting01',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'EMPEROR',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Pharrot',
              currentScore: 0,
              inGame: true,
              position: null,
            },
          ],
        },
        {
          id: 'sfmoon',
          name: 'SAFEMOON2.0',
          totalScore: 0,
          img: safemoonLogo,
          players: [
            {
              name: 'iLothbrok',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: '2packing',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Prostota',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Yuriy95',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: '5GIG_Crypto',
              currentScore: 0,
              inGame: true,
              position: null,
            },
          ],
        },
        {
          id: 'sharkx',
          name: 'Raid Sharks',
          totalScore: 0,
          img: sharksLogo,
          players: [
            {
              name: 'DeathWish',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Numig',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'cocodile',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Crypto_Gunner',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Ace101',
              currentScore: 0,
              inGame: true,
              position: null,
            },
          ],
        },
        {
          id: 'bitDog',
          name: 'BitDog',
          totalScore: 0,
          img: dogLogo,
          players: [
            {
              name: 'AndyBTCBulldog',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'D0gL0rd',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'ODBullDogMiner',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'hotbox04',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'St. Ruggle',
              currentScore: 0,
              inGame: true,
              position: null,
            },
          ],
        },
        {
          id: 'amp',
          name: 'AMP',
          totalScore: 0,
          img: ampLogo,
          players: [
            {
              name: 'Jacki',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Adrian AMP',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Blanco Dos',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Patron20221',
              currentScore: 0,
              inGame: true,
              position: null,
            },
            {
              name: 'Stan AMP',
              currentScore: 0,
              inGame: true,
              position: null,
            },
          ],
        },
      ],
    }),
    methods: {
      removePlayer(team, player) {
        const currentTeam = this.teams.filter(t => t.id === team.id)[0];
        const currentPlayer = currentTeam.players.filter(p => p.name === player.name)[0];
        currentPlayer.inGame = false;
        currentPlayer.position = this.totalPlayers;
        this.totalPlayers -= 1;
        currentTeam.totalScore += this.currentScore;
        this.currentScore += 1;
      },
    },
  });
</script>

<style>
.leaderboard h2 {
  text-align: center;
}

.leaderboard ol {
  list-style: none;
  padding: 0;
}

.leaderboard li {
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 4px;
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
}

.leaderboard .active {
  background-color: #ffd700;
}

.leaderboard .rank {
  flex: 0 0 30px;
}

.lead {
  color: #ffffff !important;
}

.leaderboard-container {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  color: #bbbbbb;
  cursor: pointer;
  display: table;
  margin-bottom: 30px;
  padding: 30px;
  width: 100%;
  transition: all 500ms;
}

.leaderboard-container h2 {
  border-bottom: 3px solid #ffffff;
  color: #ffffff;
}

.leaderboard-container li {
  background-color: transparent !important;
  color: #ffffff;
  font-size: 22px;
  display: flex;
  justify-content: space-evenly;
}

.faq-section {
  background-color: #000000;
}

#earn .card,
#earn .card-header {
  background-color: #000000 !important;
  color: #ffffff !important;
}

#earn .card-header h5 {
  color: #ffffff !important;
  text-align: center;
}

.align-self-baseline {
  align-self: baseline;
}

.card-body {
  font-size: 18px;
}

.players-container li {
  list-style: none;
}

.teams h1 {
  font-weight: 700;
  font-size: 36px;
}

.team-logo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}
</style>
